import { of, from, EMPTY, Observable, firstValueFrom } from 'rxjs';
import { take, catchError, concatMap, mapTo, shareReplay } from 'rxjs/operators';
import * as R from 'runtypes';
import { Runtype, Static, Union } from 'runtypes';
import { BigNumber } from 'bignumber.js';

import { Currency } from '../entities/currency';
import { Missing } from './runtypes';
import { HttpHeaders } from '@angular/common/http';

export const EmptyResourseResponse = R.Record({
  timestamp: R.Number,
  httpStatus: R.Number,
  message: R.String,
});
export type EmptyResourseResponse = Static<typeof EmptyResourseResponse>;

export const ResourceResponse = <T extends Runtype>(ResponseObject: T) => ResourceResponseWithErrorDetails(ResponseObject, Missing);
export const ResourceResponseWithErrorDetails = <T extends Runtype, U extends Runtype>(ResponseObject: T, ErrorResponseObject: U) =>
  EmptyResourseResponse.And(
    Union(
      R.Record({
        isSuccess: R.Literal(true),
        responseObject: ResponseObject,
      }),
      R.Record({
        isSuccess: R.Literal(false),
        errorStatus: R.Number,
        responseObject: ErrorResponseObject,
      })
    )
  );
// Unfortunately `Static` can't infer generic arguments.
// This type covers both ResourceResponse and ResourceResponseWithErrorDetails
export type ResourceResponse<T, U = undefined> = Static<typeof EmptyResourseResponse> &
  (
    | {
        isSuccess: true;
        responseObject: T;
      }
    | {
        isSuccess: false;
        errorStatus: number;
        responseObject: U;
      }
  );

export const SuccessOnlyResponse = <T extends Runtype>(ResponseObject: T) =>
  EmptyResourseResponse.extend({
    responseObject: ResponseObject,
  });
// Unfortunately `Static` can't infer generic arguments.
export type SuccessOnlyResponse<T> = Static<typeof EmptyResourseResponse> & {
  responseObject: T;
};

export const ErrorResponse = EmptyResourseResponse.extend({
  isSuccess: R.Literal(false),
});
export type ErrorResponse = Static<typeof ErrorResponse>;

export class CurrencyImage {
  constructor(readonly imageUrl: string, readonly exists: boolean) {}
}

export type DisplayLedgerEntry = {
  currency: Currency;
  ledgerTypeEn: string;
  ledgerEntryTypeEn: string;
  ledgerEntrySubTypeEn?: string;
  reversalFg?: boolean;
};

export const Utils = {
  onlyNumericAllowed(e: KeyboardEvent, allowDecimal?: boolean, allowMinus?: boolean): boolean {
    return (
      /[0-9]/.test(e.key)
      || (e.key === '-' && allowMinus)
      || (e.key === '.' && allowDecimal)
      // key was modified by either Control or Alt/Option or Meta/Command
      // ignore OS ("Windows Logo"), Super and Hyper for now
      || e.ctrlKey
      || e.altKey
      || e.metaKey
      // control keys all have proper names
      || e.key.length > 1
    );
  },

  decimalPlaces: (value: string | number | BigNumber | null): number => BigNumber(value ?? 0).dp() ?? 0,

  round: (value: number, numDecimals: number): number => parseFloat(value.toFixed(numDecimals)),

  getWebSocketProtocol: (): string => (window.location.protocol.startsWith('https') ? 'wss://' : 'ws://'),

  copyToClipboard: (text: string): void => {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  },

  largeImageExists: async (currency: Currency): Promise<CurrencyImage> => {
    try {
      return new CurrencyImage(
        // just because we've got more png at present, not that it's a good default
        await firstValueFrom(image('/assets/image/icons/icons-lg', currency.id, ['png', 'svg'])),
        true
      );
    } catch {
      return new CurrencyImage('', false);
    }
  },

  getFormattedLockPeriod: (hours?: number | null): string => (hours ? formatHours(hours) : ''),
  getFormattedLockPeriodRange: (minHours?: number | null, maxHours?: number | null): string =>
    minHours && maxHours ? formatHourRange(minHours, maxHours) : '',

  getTransactionTypeForDisplay: (txn: DisplayLedgerEntry, house = false) => {
    if (house) {
      return toTitleCase(txn.ledgerEntryTypeEn);
    } else if (txn.ledgerEntryTypeEn === 'TRADE_BUY') {
      return toTitleCase('Buy ' + (txn.ledgerEntrySubTypeEn ?? ''));
    } else if (txn.ledgerEntryTypeEn === 'TRADE_SELL') {
      return toTitleCase('Sell ' + (txn.ledgerEntrySubTypeEn ?? ''));
    } else if (txn.ledgerEntryTypeEn === 'INTEREST_CR') {
      return txn.currency.id === Currency.ZOOM ? 'Bonus Interest' : 'Interest';
    } else if (txn.ledgerEntryTypeEn === 'COLLATERAL_IN') {
      return decodeTradingLedgerTransfer(txn.ledgerTypeEn, 'COLLATERAL', true);
    } else if (txn.ledgerEntryTypeEn === 'COLLATERAL_OUT') {
      return decodeTradingLedgerTransfer(txn.ledgerTypeEn, 'COLLATERAL', false);
    } else if (txn.ledgerEntryTypeEn === 'EARN_INVEST') {
      return decodeTradingLedgerTransfer(txn.ledgerTypeEn, 'EARNING', true);
    } else if (txn.ledgerEntryTypeEn === 'EARN_REDEEM') {
      return decodeTradingLedgerTransfer(txn.ledgerTypeEn, 'EARNING', false);
    } else if (['MARGIN_TRADE_PROFIT', 'MARGIN_TRADE_LOSS'].includes(txn.ledgerEntryTypeEn)) {
      return 'Margin Trade P&L';
    } else if (txn.ledgerEntryTypeEn === 'DEPOSIT' && ['VANILLA_DIRECT', 'BLACKHAWK'].includes(txn.ledgerEntrySubTypeEn ?? '')) {
      return 'CoinZoom Cash Deposit' + (txn.reversalFg ? ' Reversal' : '');
    } else if (txn.ledgerEntryTypeEn === 'DEPOSIT_FEE' && ['VANILLA_DIRECT', 'BLACKHAWK'].includes(txn.ledgerEntrySubTypeEn ?? '')) {
      return 'CoinZoom Cash Fee' + (txn.reversalFg ? ' Reversal' : '');
    } else if (
      ['WITHDRAWAL', 'SEND_FEE', 'REWARD', 'SEND'].includes(txn.ledgerEntryTypeEn)
      && ['BLACKHAWK', 'GIFT_CARD'].includes(txn.ledgerEntrySubTypeEn ?? '')
    ) {
      return 'eGift ' + toTitleCase(txn.ledgerEntryTypeEn) + (txn.reversalFg ? ' Reversal' : '');
    } else if (txn.ledgerEntrySubTypeEn) {
      return toTitleCase(txn.ledgerEntrySubTypeEn + ' ' + txn.ledgerEntryTypeEn + (txn.reversalFg ? ' Reversal' : ''));
    } else {
      return toTitleCase(txn.ledgerEntryTypeEn + (txn.reversalFg ? ' Reversal' : ''));
    }
  },

  otpHeader: (otp?: number | null) =>
    otp
      ? {
          headers: new HttpHeaders({ 'cz-otp': `${otp}` }),
        }
      : {},
};

const decodeTradingLedgerTransfer = (ledgerType: string, associatedLedgerType: string, inbound: boolean) => {
  const collapsedLedgerType = ledgerType === 'STAKING' ? 'EARNING' : ledgerType;
  if (collapsedLedgerType === associatedLedgerType) {
    return inbound ? 'From Trading' : 'To Trading';
  } else {
    return inbound ? toTitleCase(`To ${associatedLedgerType}`) : toTitleCase(`From ${associatedLedgerType}`);
  }
};

const toTitleCase = (phrase: string) => {
  return phrase
    .trim()
    .replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * @param suffixes are in priority order
 *
 * FIXME this causes 404 errors to be displayed in the console. The correct fix appears to be to do this suffix
 * switching within the Nginx proxy which also means replacing the current local dev proxy. Probably a good thing
 * anyway, as that way Nginx issues in downstream environments can be detected locally.
 */
export const image = (path: string, name: string, suffixes: Array<string>): Observable<string> => {
  return of(...suffixes).pipe(
    concatMap((suffix) => {
      // TODO would be simpler to do a HEAD call but that means injecting HttpClient which can't be done until
      // this is a real Angular Library
      const img = new Image();
      img.src = `${path}/${name}.${suffix}`;
      return from(img.decode()).pipe(
        mapTo(img.src),
        catchError(() => EMPTY) // ignore errors
      );
    }),
    take(1),
    shareReplay(1)
  );
};

const formatHours = (totalHours: number): string => {
  const days = Math.floor(totalHours / 24);
  const hours = totalHours - days * 24;

  let result = '';
  if (days === 1) {
    result = '1 day';
  } else if (days > 1) {
    result = `${days} days`;
  }

  if (hours === 1) {
    result += result.length === 0 ? '1 hour' : ' 1 hour';
  } else if (hours > 1) {
    result += result.length === 0 ? `${hours} hours` : ` ${hours} hours`;
  }

  return result;
};

const formatHourRange = (minHoursTotal: number, maxHoursTotal: number): string => {
  const minDays = Math.floor(minHoursTotal / 24);
  const minHours = minHoursTotal - minDays * 24;

  const maxDays = Math.floor(maxHoursTotal / 24);
  const maxHours = maxHoursTotal - maxDays * 24;

  if (minHours === 0 && maxHours === 0) {
    // The normal case, the other is for completeness
    return `${minDays} - ${maxDays} days`;
  } else {
    return `${formatHours(minHoursTotal)} - ${formatHours(maxHoursTotal)}`;
  }
};
