import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, forkJoin, Observable, of, ReplaySubject, timer } from 'rxjs';
import { map, share, shareReplay, switchMap } from 'rxjs/operators';
import * as R from 'runtypes';

import { CardPaymentHistoryRequest, CardPaymentRecord, CardType } from 'entities/spend.card';
import { URLS } from 'commons/app.constant';
import { Currency, Enum, MarketService, Utils } from 'core';

@Injectable({
  providedIn: 'root',
})
export class SpendWalletService {
  constructor(private readonly http: HttpClient, private readonly marketService: MarketService) {}

  // TODO card availability changes based on customer country
  readonly isCardAvailable: Observable<boolean> = this.http.get(URLS.debitCardAvailable).pipe(
    map(CardAvailableResponse.check),
    map((data) => data.available),
    shareReplay()
  );

  areCardTransactionsAvailable(): Observable<boolean> {
    return combineLatest([
      this.isCardAvailable,
      this.http.get(URLS.checkCardAvailable).pipe(
        map(CardAvailableResponse.check),
        map((data) => data.available),
        shareReplay()
      ),
    ]).pipe(map(([isCardAvailable, areTransactionsAvailable]) => isCardAvailable || areTransactionsAvailable));
  }

  getSpendCurrencySymbol(): Observable<string | null> {
    return this.http.get(URLS.debitCardSpendCurrency).pipe(
      map(AccountCurrencyResponse.check),
      map((response) => response.currencyCd)
    );
  }

  saveSpendCurrency(spendCurrency: string): Observable<void> {
    return this.http.put<void>(URLS.debitCardSpendCurrency, { currencyCd: spendCurrency });
  }

  // Cache for 15 minutes as this changes very infrequently
  readonly cardMetadata: Observable<Array<CardMetaData>> = this.http.get(URLS.debitCardMetadata).pipe(
    map(R.Array(CardMetaData).check),
    share({
      connector: () => new ReplaySubject(),
      resetOnComplete: () => timer(15 * 60 * 1000),
      resetOnRefCountZero: false,
    })
  );

  orderCard(shipping: CardShippingMethod, address: ShippingAddress, otp: number): Observable<CardOrderStatus> {
    return this.http.post(URLS.orderDebitCard, { shipping, address }, Utils.otpHeader(otp)).pipe(map(CardOrderStatus.check));
  }

  reissueCard(reason: CardReissueReason, shipping: CardShippingMethod, address: ShippingAddress, otp: number): Observable<CardOrderStatus> {
    return this.http
      .post(URLS.reissueDebitCard, { cardType: CardTypeApi.PHYSICAL, reason, shipping, address }, Utils.otpHeader(otp))
      .pipe(map(CardOrderStatus.check));
  }

  getAccountStatus(): Observable<CardAccountStatus> {
    return this.http.get(URLS.debitCardAccountStatus).pipe(map(CardAccountStatus.check));
  }

  getCardPayments(request: CardPaymentHistoryRequest): Observable<Array<CardPaymentRecord>> {
    return this.marketService.currencies.pipe(
      switchMap((currencies) => {
        const currenciesById = new Map<string, Currency>();
        currencies.forEach((c) => currenciesById.set(c.id, c));

        return this.http
          .post<Array<any>>(URLS.cardPaymentsUrl, request)
          .pipe(map((rawData) => rawData.map((rd) => new CardPaymentRecord(rd, currenciesById))));
      })
    );
  }

  freezeCard(cardType: CardType, freeze: boolean, otp: number): Observable<unknown> {
    const freezeVirtual =
      cardType !== CardType.PHYSICAL
        ? this.http.post<never>(URLS.freezeDebitCard, { cardType: CardTypeApi.VIRTUAL, frozen: freeze }, Utils.otpHeader(otp))
        : of(undefined);
    const freezePhysical =
      cardType !== CardType.VIRTUAL
        ? this.http.post<never>(URLS.freezeDebitCard, { cardType: CardTypeApi.PHYSICAL, frozen: freeze }, Utils.otpHeader(otp))
        : of(undefined);
    return forkJoin([freezeVirtual, freezePhysical]);
  }

  cancelCard(cardType: CardType, otp: number): Observable<unknown> {
    return this.http.post<never>(
      URLS.cancelDebitCard,
      { cardType: cardType === CardType.VIRTUAL ? CardTypeApi.VIRTUAL : CardTypeApi.PHYSICAL },
      Utils.otpHeader(otp)
    );
  }
}

const CardAvailableResponse = R.Record({
  available: R.Boolean,
});

const AccountCurrencyResponse = R.Record({
  currencyCd: R.String.nullable(),
});

export enum CardLevel {
  SELECT = 'Select',
  PREFERRED = 'Preferred',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  BLACK = 'Black',
}

export enum CardStatus {
  ACTIVE = 'ACTIVE',
  LOST = 'LOST',
  CANCELLED = 'CANCELLED',
}

export enum CardSubStatus {
  ISSUED = 'ISSUED',
  ACTIVATED = 'ACTIVATED',
  FROZEN = 'FROZEN',
  BLOCKED = 'BLOCKED',
}

export const DebitCard = R.Record({
  cardId: R.String,
  maskedCardNumber: R.String,
  isVirtual: R.Boolean,
  status: Enum(CardStatus),
  subStatus: Enum(CardSubStatus),
});
export type DebitCard = R.Static<typeof DebitCard>;

const CardAccountStatus = R.Record({
  level: Enum(CardLevel).nullable(),
  virtualCard: DebitCard.nullable(),
  physicalCard: DebitCard.nullable(),
  lifetimeRewards: R.Number.nullable(),
  historicCards: R.Array(DebitCard),
});
export type CardAccountStatus = R.Static<typeof CardAccountStatus>;

export const isFrozen = (card: DebitCard) => card.status === CardStatus.ACTIVE && card.subStatus === CardSubStatus.FROZEN;

const CardLevelConfig = R.Record({
  id: Enum(CardLevel),
  qualifyingZoomBalanceAmt: R.Number,
  standardUsMailFeeUSDAmt: R.Number.nullable(),
  priorityUsMailFeeUSDAmt: R.Number.nullable(),
  standardInternationalMailFeeUSDAmt: R.Number.nullable(),
  priorityInternationalMailFeeUSDAmt: R.Number.nullable(),
  replacementCardFeeUSDAmt: R.Number.nullable(),
});

const CardLevelRewards = R.Record({
  qualifyingZoomBalanceAmt: R.Number,
  rewardsPct: R.Number,
  tradeFeeDiscountPct: R.Number,
});

const CardLevelLimits = R.Record({
  dailyLimit: R.Number,
});

const CardMetaData = R.Record({
  level: CardLevelConfig,
  rewards: CardLevelRewards,
  limits: CardLevelLimits,
});
export type CardMetaData = R.Static<typeof CardMetaData>;

export enum CardShippingMethod {
  STANDARD_US = 'STANDARD_US',
  PRIORITY_US = 'PRIORITY_US',
  STANDARD_INTERNATIONAL = 'STANDARD_INTERNATIONAL',
  PRIORITY_INTERNATIONAL = 'PRIORITY_INTERNATIONAL',
}

export type ShippingAddress = {
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  stateCode: string;
  postCode: string;
  countryIsoCode3: string;
};

export enum CardProcessingStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  REJECTED = 'REJECTED',
}

const CardOrderStatus = R.Record({
  status: Enum(CardProcessingStatus),
  card: DebitCard.nullable(),
});

export type CardOrderStatus = R.Static<typeof CardOrderStatus>;

export enum CardReissueReason {
  DAMAGED = 'DAMAGED',
  EXPIRED = 'EXPIRED',
  LOST = 'LOST',
  FRAUD = 'FRAUD',
}

export enum CardTypeApi {
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical',
}
